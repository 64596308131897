import React from 'react';
import RgbHexDisplay from './RgbHexDisplay';
import Information from './Information';
import Thanks from './Thanks';

export default function RgbHexView(props) {
    const {
        setRgb,
        setHex,
        rgb = '',
        hex = ''
    } = props;

    return (
        <div className="container-fluid">
            <div className="toolSection">
                <div className="col-sm-12">
                    <h3>Rgb/Hex Converter</h3>
                    <p>Quick color converter for getting hex or rgb values.</p>
                </div>
                <div className="row gapBottom">
                    <div className="col-sm-6">
                        <label htmlFor="hex">Rgb/a</label>
                        <input className="form-inline form-control-lg"
                               name="rgb"
                               value={rgb}
                               onChange={setRgb}/>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="rgb">Hex: </label>
                        <input className="form-inline form-control-lg"
                               name="rgb"
                               value={hex}
                               onChange={setHex}/>
                    </div>
                </div>
                <div className="row gapBottom">
                    <div className="gapTop col-sm-12" style={{
                        minHeight: 100,
                        width: '100%',
                        border: 'dashed 1px #333',
                        backgroundColor: rgb ? `rgba(${rgb})` : 'transparent',
                        color: getBackgroundTextColor(rgb),
                        textAlign: 'center',
                        padding: 40,
                    }}>
                        {
                            <RgbHexDisplay hex={hex} rgb={rgb}/>
                        }
                    </div>
                </div>
                <div className="col-sm-12">
                    <Information/>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <Thanks/>
                </div>
            </div>
        </div>
    );
}

function getBackgroundTextColor(rgb) {
    const dark = '#000';
    const med = '#ccc';
    const light = '#fff';
    const rgbObject = convertRgbStringToRgbObject(rgb);

    if (!rgbObject) {
        return dark;
    }

    const {red = 0, green = 0, blue = 0, alpha = 1} = rgbObject;
    const colorAverage = ((red + green + blue) * alpha) / 3;
    const lightOrDark = (colorAverage > 128) ? dark : light;

    return (colorAverage > 100 && colorAverage < 140) ? med : lightOrDark
}

function convertRgbStringToRgbObject(rgb) {
    if (!rgb || typeof rgb !== 'string' || (rgb.match(/,/g) || []).length < 2) {
        return false;
    }

    rgb = rgb.replace(/rgba?\(/, '');
    rgb = rgb.replace(/\)/, '');
    const parts = rgb.split(',');

    let alpha = parts.length === 4 && parseFloat(parts[3]);
    typeof alpha === 'number' || (alpha = 1);

    return {
        red: parseInt(parts[0]),
        green: parseInt(parts[1]),
        blue: parseInt(parts[2]),
        alpha,
    };
}

import React, {MutableRefObject, useEffect, useState} from 'react';
import ClockDisplay from "./ClockDisplay";

export interface TimerProps {
    startingTime: number;
    startRef?: MutableRefObject<(() => void) | undefined>;
    pauseRef?: MutableRefObject<(() => void) | undefined>;
    resetRef?: MutableRefObject<(() => void) | undefined>;
    hasFinished?: () => void;
    [key: string]: any;
}

let timer;

export default function Timer({startingTime = 300, startRef, pauseRef, resetRef, hasFinished, ...args}: TimerProps) {
    const [time, setTime] = useState<number>(startingTime);
    const [isStarted, setIsStarted] = useState<boolean>(false);

    useEffect(() => {
        setTime(startingTime);
    }, [startingTime])

    const start = () => setIsStarted(true);
    const pause = () => setIsStarted(false);
    const reset = () => {
        setTime(startingTime);
        setIsStarted(false);
    }

    if (startRef) {
        startRef.current = start;
    }

    if (pauseRef) {
        pauseRef.current = pause;
    }

    if (resetRef) {
        resetRef.current = reset;
    }

    useEffect(() => {
        if (!isStarted) return;

        if (time === 0 && hasFinished) {
            hasFinished();
        }

        if (time < 1) return () => clearInterval(timer);

        timer = setInterval(() => {
            setTime(time - 1);
        }, 1000);

        return () => clearInterval(timer);
    });

    return (
        <ClockDisplay {...args}>{time}</ClockDisplay>
    );
}

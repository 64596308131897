import React from 'react';
import Analytics from '../../services/analytics/Analytics';
import {Col, Container, Row} from "react-bootstrap";

export const keywords = 'survey';

export default function Survey() {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <h3>Survey</h3>
                    <p>Click on which you rather see us focus on first.</p>
                    <button className="btn btn-primary" onClick={() => {
                        Analytics.event({
                            category: "User",
                            action: "survey: more resources"
                        });
                    }}>More Resources</button>
                    {' '}
                    <button className="btn btn-primary" onClick={() => {
                        Analytics.event({
                            category: "User",
                            action: "survey: more tools"
                        });
                    }}>More Tools</button>
                </Col>
            </Row>
        </Container>
    );
}

import React from 'react';

export default function Information() {
    return (
        <div>
            <p>
                Each format will work:
            </p>
            <h5>Rgb</h5>
            <ul>
                <li><code>123, 234, 132</code></li>
                <li><code>123, 234, 132, 0.5</code></li>
                <li><code>rgba(123, 234, 132,0.5)</code> This will return css4 hex 8 digit, which is not fully support
                    yet.
                </li>
            </ul>
            <h5>Hex</h5>
            <ul>
                <li><code>#efe</code></li>
                <li><code>#7bea84</code></li>
                <li><code>7bea84</code></li>
                <li><code>7bea8480</code> This is 8 digit hex, which affects alpha. It is css4 and is not fully
                    supported yet.
                </li>
            </ul>
        </div>
    );
}

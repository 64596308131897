import ReactDOMServer from 'react-dom/server';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import pretty from "pretty";

export default function Code({children}) {
    const markup = ReactDOMServer.renderToStaticMarkup(children);

    return (
        <>
            {children}
            <SyntaxHighlighter showLineNumbers wrapLines language="markup" style={dracula}>
                {pretty(markup)}
            </SyntaxHighlighter>
        </>
    );
};

import React, {PureComponent} from 'react';
import Analytics from '../../services/analytics/Analytics';
import xmlButPrettier from 'xml-but-prettier';

export default class XmlPrettier extends PureComponent {
  static getName() {
    return 'xml prettier';
  }

  constructor(props) {
    super(props);

    this.state = {
      ugly: '',
      pretty: '',
    };

    this.setUgly = this.setUgly.bind(this);
  }

  setUgly({target}) {
    const ugly = target.value;
    let pretty = '';

    try {
      pretty = xmlButPrettier(ugly);
    } catch (error) {
      pretty = `error: ${error.message}`;
    }

    Analytics.event({
      category: 'User',
      action: `used ${this.constructor.getName()}`,
    });

    this.setState(state => ({...state, pretty, ugly}));
  }

  render() {
    return (
        <div className="container-fluid">
          <div className="toolSection">
            <div className="row">
              <div className="col-sm-12">
                <h3>Xml Prettier</h3>
                <p>Simple xml pretty formatter, just paste or type in the field
                  and it'll show in the opposite. </p>
              </div>
              <div className="col-lg-12">
                <label htmlFor="uglyXml">Ugly Xml: </label>
                <h5>The xml that you want to make pretty</h5>
                <textarea className="form-control-lg"
                          name="uglyXml"
                          value={this.state.ugly}
                          onChange={this.setUgly}/>
              </div>
              <div className="col-lg-12">
                <label htmlFor="prettyXml">Pretty Xml: </label>
                <h5>You now have pretty xml.</h5>
                <textarea className="form-control-lg"
                          name="prettyXml"
                          readOnly
                          value={this.state.pretty}/>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <hr/>
                <p>Thanks to the package <a
                    rel="noreferrer"
                    href="https://www.npmjs.com/package/xml-but-prettier"
                    target="_blank">xml-but-prettier</a>!</p>
              </div>
            </div>
          </div>
        </div>
    );
  }
};

import {
  EVENT_TRIGGER_POST_SET_COOKIE,
  EVENT_TRIGGER_PRE_SET_COOKIE,
} from '../event/CookieEvents';

const cookies = require('browser-cookies');

export default class Cookie {

  constructor(options = null) {
    // expires 1 day.
    this.defaults = {
      secure: true,
      expires: 1,
    };

    if (options) {
      this.defaults = {...this.defaults, ...options};
    }
  }

  set(name, value, options) {

    if (!document || typeof document.dispatchEvent !== 'function') {
      console.debug('document not available to trigger event');

      return false;
    }

    document.dispatchEvent(new CustomEvent(EVENT_TRIGGER_PRE_SET_COOKIE, {
      detail: {
        name,
        value,
        options,
      },
      bubbles: true,
    }));

    const cookie = cookies.set(name, '' + value, {...this.defaults, ...options});

    document.dispatchEvent(new CustomEvent(EVENT_TRIGGER_POST_SET_COOKIE, {
      bubbles: true,
    }));

    return cookie;
  }

  get(name) {
    return cookies.get(name);
  }

  delete(name) {
    return cookies.erase(name);
  }
};


import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import config from '../../services/config';
import {MdWidgets} from "react-icons/md";
import {BsCardHeading} from "react-icons/bs";
import TopNav from "../../layout/nav/TopNav";
import cookie from "browser-cookies";
import {GrPowerReset} from "react-icons/all";
import ToolsResponsiveView, {TOOLS_LAYOUT} from "./ToolsResponsiveView";
import ToolsCardView from './ToolsCardView';
import {getComponent} from "./CardViewItem";
import Panel, {PanelVariant} from "../../layout/panel/Panel";
import {Container} from "react-bootstrap";

const toolLayouts = config.layouts.tools;

export enum ViewMode {
    cards,
    widgets
}

export default function Tools() {
    const [toolsConfig, setToolsConfig] = useState(toolLayouts);

    const [viewMode, setViewMode] = useState(ViewMode.widgets);

    const resetLayout = () => {
        const confirm = window.confirm("Are you sure you want to reset your layout?");

        if (confirm) {
            cookie.erase(TOOLS_LAYOUT);

            window.location.reload();
        }
    };

    const searchCallbackHandler = ({currentTarget: {value}}) => {
        if (!value) {
            setToolsConfig(toolLayouts);
            return;
        }

        const searchTerm = String(value).toLowerCase();

        const toolLayoutBySearchTerm = (toolLayout) => {
            const toolSanitizedName = String(toolLayout.i).toLowerCase();

            return toolSanitizedName.includes(searchTerm);
        }

        const matchingTools: any = {};

        matchingTools.lg = toolLayouts.lg.filter(toolLayoutBySearchTerm);
        matchingTools.md = toolLayouts.md.filter(toolLayoutBySearchTerm);
        matchingTools.xxs = toolLayouts.xxs.filter(toolLayoutBySearchTerm);

        setToolsConfig(matchingTools);
    };

    return (
        <>
            <Helmet>
                <title>Tools</title>
            </Helmet>
            <TopNav title="Tools" searchCallback={searchCallbackHandler}>
                <button title="Change View Modes" className="button trimary"
                        type="button"
                        onClick={() => setViewMode(viewMode === ViewMode.widgets ? ViewMode.cards : ViewMode.widgets)}>
                    {
                        viewMode === ViewMode.widgets ? <BsCardHeading/> : <MdWidgets/>
                    }
                </button>
                <>
                    {
                        viewMode === ViewMode.widgets &&
                        <button title="Reset layout"
                                className="button trimary"
                                type="button"
                                onClick={resetLayout}>
                          <GrPowerReset/>
                        </button>
                    }
                </>
            </TopNav>
            <Container fluid>
                <Panel variant={viewMode === ViewMode.widgets ? PanelVariant.transparent : PanelVariant.white}>
                    {
                        viewMode === ViewMode.widgets
                            ? <ToolsResponsiveView toolsConfig={toolsConfig} getComponent={getComponent}/>
                            : <ToolsCardView toolsConfig={toolsConfig}/>
                    }
                </Panel>
            </Container>
        </>
    );
}

import React, { useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as CopyIcon} from "../../assets/icons/file_copy.svg";
import CopyToClipboard from 'react-copy-to-clipboard';
import Analytics from "../../services/analytics/Analytics";

export const keywords = 'colorpicker hex rgb';

function hexToRgb(hex) {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

        return "rgb(" + r + ", " + g + ", " + b + ")";
}

export default function Colorpicker() {
    const [color, setColor] = useState('#000000');

    return (
        <Container fluid>
            <h3>Colorpicker</h3>
            <Row className="d-flex">
                <Col>
                    <Row className="justify-content-center">Pick a color</Row>
                    <Row className="justify-content-center">
                        <input
                            type="color"
                            value={color}
                            onChange={e => setColor(e.target.value)}
                            style={{width:'100px'}}
                        />
                    </Row>
                </Col>
                <Col>
                    <Row className="justify-content-center">Hex</Row>
                    <Row className="justify-content-center">
                        {color}
                        <CopyToClipboard text={color}>
                            <CopyIcon onClick={() => {
                                Analytics.event({
                                    category: 'codeByPete',
                                    action: `Colorpicker copy clicked`,
                                })
                            }} style={{fill: 'currentColor'}}/>
                        </CopyToClipboard>
                    </Row>
                </Col>
                <Col>
                    <Row className="justify-content-center">RGB</Row>
                    <Row className="justify-content-center">
                        {hexToRgb(color)}
                        <CopyToClipboard text={hexToRgb(color)}>
                            <CopyIcon onClick={() => {
                                Analytics.event({
                                    category: 'codeByPete',
                                    action: `Colorpicker copy clicked`,
                                })
                            }} style={{fill: 'currentColor'}}/>
                        </CopyToClipboard>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

import React, {useState} from "react";

interface URIEncodeDecodeProps {
    decoded: string;
    encoded: string;
}

export default function URIEncodeDecode() {
    const [inputOutput, setInputOutput] = useState<URIEncodeDecodeProps>({
        encoded: '',
        decoded: ''
    });

    const setDecodedHandler = ({currentTarget}) => {
        const value = currentTarget.value;

        const decodedValue = encodeURIComponent(value);

        setInputOutput({
            decoded: value,
            encoded: decodedValue
        });
    };

    const setEncodedHandler = ({currentTarget}) => {
        const value = currentTarget.value;

        const encodedValue = decodeURIComponent(value);

        setInputOutput({
            decoded: encodedValue,
            encoded: value
        });
    };

    return (
        <div className="container-fluid">
            <div className="toolSection">
                <div className="row">
                    <div className="col-sm-12">
                        <h3>URI Encode/Decode</h3>
                        <p>
                            You can encode/decode a URI (string or url). This is great for something such as a encoded item you need to read from
                            the local storage (inspector).
                        </p>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="decoded">Decoded: </label>
                        <textarea name="decoded" onChange={setDecodedHandler} value={inputOutput.decoded}/>
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="encoded">Encoded: </label>
                        <textarea name="encoded" onChange={setEncodedHandler} value={inputOutput.encoded}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

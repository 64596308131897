import {ReactNode} from "react";

export const CONFIG = 'CONFIG';
export const ERROR = 'ERROR';
export const MESSAGE = 'MESSAGE';
export const MODAL_CONTENT = 'MODAL_CONTENT';
export const ACTION_CLOSE_MODAL = 'ACTION_CLOSE_MODAL';
export const EXTENSION_FORM_DATA = 'EXTENSION_FORM_DATA';

export interface MainState {
    [ERROR]?: string;
    [MESSAGE]?: string | string[];
    [MODAL_CONTENT]: {
        body: string | ReactNode;
        title?: string | ReactNode;
        footer?: string | ReactNode;
    };
    [ACTION_CLOSE_MODAL]?: never;
    [EXTENSION_FORM_DATA]?: Record<string, any>;
}

export default function reducer(state: any, action: any): MainState {
    console.log('action', action);

    switch (action.type) {
        case CONFIG:
            return {...state, [CONFIG]: action[CONFIG]};
        case ERROR:
            return {...state, [ERROR]: action[ERROR]};
        case MESSAGE:
            return {...state, [MESSAGE]: action[MESSAGE]};
        case MODAL_CONTENT:
            return {...state, [MODAL_CONTENT]: action[MODAL_CONTENT]};
        case ACTION_CLOSE_MODAL:
            return {...state, [MODAL_CONTENT]: undefined};
        case EXTENSION_FORM_DATA:
            return {...state, [EXTENSION_FORM_DATA]: action[EXTENSION_FORM_DATA]};
        default:
            throw new Error(`${action.type} is an invalid reducer type`);
    }
};

import React, {PureComponent} from 'react';
import Analytics from '../../services/analytics/Analytics';
import RgbHexView from './components/RgbHexView';
import convertRgbObjectToRgbString from '../../services/rgba/rgbaObjectToRgbaStringConverter';
import rgbHex from 'rgb-hex';
import hexRgb from 'hex-rgb';

export default class RgbHex extends PureComponent {
    readonly state = {
        rgb: '',
        hex: ''
    };

    static getName() {
        return 'hex rgb converter color';
    }

    constructor(props) {
        super(props);

        this.setRgb = this.setRgb.bind(this);
        this.setHex = this.setHex.bind(this);
    }


    render() {
        return <RgbHexView
            setHex={this.setHex}
            setRgb={this.setRgb}
            rgb={this.state.rgb}
            hex={this.state.hex}
        />;
    }

    setRgb({target}) {
        const rgb = target.value;

        try {
            const hex = rgbHex(rgb);
            Analytics.event({
                category: 'User',
                action: `used ${RgbHex.getName()} setRgb`,
            });
            this.setState(state => ({
                ...state,
                hex,
                rgb
            }));
        } catch (Error) {
            // if it errors, we only care  to track original value
            this.setState(state => ({
                ...state,
                rgb,
                hex: ''
            }));
        }
    }

    setHex({target}) {
        const hex = target.value;

        try {
            const rgb = hexRgb(hex);
            Analytics.event({
                category: 'User',
                action: `used ${RgbHex.getName()} setHex`,
            });
            this.setState(state => ({
                ...state,
                hex,
                rgb: convertRgbObjectToRgbString(rgb),
                isValid: true,
            }));
        } catch (Error) {
            console.log(Error.message);
            // if it errors, we only care  to track original value
            this.setState(state => ({
                ...state,
                hex,
                rgb: '',
                isValid: false,
            }));
        }
    }

};

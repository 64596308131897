import React from 'react';
import classnames from 'classnames';

export default Dashboard;

function Dashboard({set, children, style}: any): any {
    const key = set || 'A';

    return (
        <div style={style} className={classnames('dashboard', {[key]: true})}>
            {children}
        </div>
    );
}

import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as CopyIcon} from "../../assets/icons/file_copy.svg";
import CopyToClipboard from 'react-copy-to-clipboard';
import Analytics from "../../services/analytics/Analytics";

export const keywords = 'base64 encode decode';

export default function Timestamp() {
    const [timestamp, setTimestamp] = useState<number>(0);

    useEffect(() => {
        const ticker = setInterval(() => {
            setTimestamp(Date.now());
        }, 243)

        return () => clearInterval(ticker);
    }, []);

    return (
        <Container fluid>
            <h3>Timestamp</h3>
            <Row className="d-flex">
                <Col>
                    <Row className="justify-content-center">Unix/Epoch Time</Row>
                    <Row className="justify-content-center">
                        {timestamp}
                        <CopyToClipboard text={timestamp}>
                            <CopyIcon onClick={() => {
                                Analytics.event({
                                    category: 'User',
                                    action: `Unix/Epoch Time copy clicked`,
                                })
                            }} style={{fill: 'currentColor'}}/>
                        </CopyToClipboard>
                    </Row>
                </Col>
                <Col>
                    <Row className="justify-content-center">Milliseconds</Row>
                    <Row className="justify-content-center">
                        {Math.floor(timestamp/1000)}
                        <CopyToClipboard text={Math.floor(timestamp/1000)}>
                            <CopyIcon onClick={() => {
                                Analytics.event({
                                    category: 'User',
                                    action: `Milliseconds copy clicked`,
                                })
                            }} style={{fill: 'currentColor'}}/>
                        </CopyToClipboard>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

import formatAlpha from './formatAlpha';

export default function rgbaObjectToRgbaStringConvertor(rgb, includeAlpha = true) {
  if (
      !rgb
      || typeof rgb !== "object"
      || !rgb.hasOwnProperty("red")
      || !rgb.hasOwnProperty("green")
      || !rgb.hasOwnProperty("blue")
  ) {
    return false;
  }

  let alpha = rgb.hasOwnProperty("alpha") && formatAlpha(rgb.alpha);
  alpha = alpha !== false ? alpha : 1; // if we get false back, the we default to full alpha
  let alphaSection = `,${alpha}`;
  return `${rgb.red},${rgb.green},${rgb.blue}${includeAlpha && alphaSection}`;
}

import React from "react";
import {ReactComponent as MoveIcon} from "../../assets/icons/open_with.svg";
import ResponsiveWidgets from "./ResponsiveWidgets";
import Panel from "../../layout/panel/Panel";

export const TOOLS_LAYOUT = 'TOOLS_LAYOUT';

export default function ToolsResponsiveView({toolsConfig, getComponent}) {
    if (!toolsConfig?.lg || toolsConfig.lg.length < 1) {
        return <Panel><p>No Tools found, try another search.</p></Panel>;
    }

    return (
        <ResponsiveWidgets toolsConfig={toolsConfig} element={(toolConfig) => {
            const component = getComponent(toolConfig.i) || null;

            return (
                <div key={toolConfig.i} className="dashboard draggable"
                     style={{overflow: 'hidden'}}>
                    <div className="dragHandle"><MoveIcon/></div>
                    {component}
                </div>
            );
        }}/>
    );
};

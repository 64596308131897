import React from 'react';
import ErrorBoundary from './layout/ErrorBoundary';
import LayoutBase from './layout/LayoutBase';
import LayoutSidebarMain from './layout/sidebar/Sidebar';
import PageElement from './pages/PageElements';
import Resources from './pages/Resources';
import Tools from './pages/Tools/Tools';
import {Route} from 'react-router-dom';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './assets/scss/styles.scss';
import SetupBaseState from "./state/SetupBaseState";
import MainStateManager from "./state/stateManager/mainStateManager";
import {EXTENSION_FORM_DATA, MODAL_CONTENT} from "./state/stateManager/mainReducer";
import Timestamp from "./tools/Timestamp/Timestamp";
import Base64EncodeDecode from "./tools/Base64EncodeDecode/Base64EncodeDecode";
import Binary from "./tools/Binary/Binary";
import RgbHex from "./tools/RgbHex";
import Hex from "./tools/Hex/Hex";
import ListModifier from "./tools/ListModifier/ListModifier";
import XmlPrettier from "./tools/XmlPrettier/XmlPrettier";
import URIEncodeDecode from "./tools/URIEncodeDecode.tsx/URIEncodeDecode";
import {setComponents} from "./pages/Tools/CardViewItem";
import Timer from "./tools/Timer/Timer";
import ColorPicker from "./tools/Colorpicker/Colorpicker";
import Survey from "./tools/Survey/Survey";

const App: React.FC = () => {
    let componentList = {
        'Timestamp': <Timestamp/>,
        'Base64': <Base64EncodeDecode/>,
        'Binary': <Binary/>,
        'RgbHex': <RgbHex/>,
        'Colorpicker': <ColorPicker/>,
        'Hex': <Hex/>,
        'List Modifier': <ListModifier/>,
        'XML Prettier': <XmlPrettier/>,
        'URI Encode Decode': <URIEncodeDecode/>,
        'Timer': <Timer startingSeconds={0}/> ,
        'Survey': <Survey/>,
    };

    setComponents(componentList);

    return (
        <MainStateManager initialState={{
            [MODAL_CONTENT]: {
                body: ""
            },
            [EXTENSION_FORM_DATA]: {
                timer: {
                    status: 'stop'
                }
            }
        }}>
            <LayoutBase>
                <ErrorBoundary>
                    <SetupBaseState>
                        <LayoutSidebarMain>
                            <Route path="/" exact={true} component={Tools}/>
                            <Route path="/resources" component={Resources}/>
                            <Route path="/PageElement" component={PageElement}/>
                        </LayoutSidebarMain>
                    </SetupBaseState>
                </ErrorBoundary>
            </LayoutBase>
        </MainStateManager>
    );
};

export default App;

import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import config from "../../services/config";
import Timer from "../../layout/Timer";

const timerSets = config.timerSets;

export interface TimerProps {
    startingSeconds?: number;
    stopCallback?: (clearTimer: () => void) => void;
}

export default function TimerTool({startingSeconds = 0, stopCallback}: TimerProps) {
    const [countdownTime, setCountdownTime] = useState<number>();

    useEffect(() => {
       if (!countdownTime) {
           setCountdownTime(startingSeconds);
        }
    }, [startingSeconds, countdownTime]);

    const timer = document.querySelector(".timer")

    const startRef = useRef<() => void>();
    const pauseRef = useRef<() => void>();
    const resetRef = useRef<() => void>();

    const hasFinished = () => {
        timer && timer.classList.add("isDone");
        timer && window.scrollTo(0, timer.getBoundingClientRect().y);
    }

    const start = () => {
        timer && timer.classList.remove("isDone");
        startRef?.current && startRef.current();
    }
    const pause = () => pauseRef?.current && pauseRef.current();
    const reset = () => {
        setCountdownTime(0);
        timer && timer.classList.remove("isDone");
        resetRef?.current && resetRef.current();
    }

    const setTimer = (timerSet) => function() {
        pause();
        setCountdownTime(countdownTime + timerSet.seconds)
    };

    return (
        <Container fluid className="timer">
            <Row>
                <h2>Timer</h2>
            </Row>
            <Row className="justify-content-center d-flex">
                <Col className="d-flex justify-content-center">
                    <Timer startingTime={countdownTime || 0} startRef={startRef} pauseRef={pauseRef} resetRef={resetRef} hasFinished={hasFinished}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <button className="large timer-start" type="button" onClick={start}>Start</button>
                </Col>
                <Col>
                    <button className="large timer-pause" type="button" onClick={pause}>Pause</button>
                </Col>
                <Col>
                    <button className="large timer-reset" type="button" onClick={reset}>Reset</button>
                </Col>
            </Row>
            <Row>
                {
                    timerSets.map((timerSet, index) => {
                        return <Col key={index} className="justify-content-center d-flex">
                            <button onClick={setTimer(timerSet)} className="small no-word-break">+ {timerSet.name.toLowerCase()}</button>
                        </Col>;
                    })
                }
            </Row>
        </Container>
    );
}

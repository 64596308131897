import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {Helmet} from "react-helmet";

const LayoutBase = ({children, seo = {}}) => {
    const defaultKeywords = [
        'base64',
        'xml parser',
        'json parser',
        'hex',
        'prettier',
        'tools',
        'code',
        'coder',
        'exchange',
        'pageElement',
        'ipsum',
        'rgb',
        'resources'
    ];
    const defaultDescription = 'A helpful resource of tools and guides for development, such as base64 decode encode, xml prettier, background noise and more.';

    return (
        <div id="app">
            <div className="baseStyle">
                <Helmet
                    defaultTitle="coder.exchange"
                    titleTemplate="coder.exchange - %s"
                >
                    <meta charSet="utf-8"/>
                    <meta name="description" content={defaultDescription}/>
                    <meta name="keywords" content={defaultKeywords.join(',')}/>
                    <meta name="author" content="Peter DeChamp Richardson"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                </Helmet>
                <Router>
                    {children}
                </Router>
            </div>
        </div>
    );
};

export default LayoutBase;

import CardViewItem from "./CardViewItem";
import React from "react";
import {Col} from "react-bootstrap";
import { Row } from "react-bootstrap";

export default function ToolsCardView({toolsConfig}) {
    return (
        <Row>
            {toolsConfig.lg.map((toolConfig) => {
                return (
                    <Col>
                        <CardViewItem key={toolConfig.i} {...toolConfig}/>
                    </Col>
                );
            })}
        </Row>
    );
}

import {Component} from "react";

type ErrorBoundaryState = {
    hasError: boolean;
};

type ErrorBoundaryProps = {
    message?: string;
};

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    readonly state = {
        hasError: false
    };

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.log(error, info);
    }

    render() {
        // if (this.state.hasError) {
        //     // You can render any custom fallback UI
        //     return <div>{this.props.message || 'Something went wrong.'}</div>;
        // }

        return this.props.children;
    }
}

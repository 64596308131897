import React, {useState} from 'react';
import Analytics from '../../services/analytics/Analytics';
import {Col, Container, Row} from "react-bootstrap";

export const keywords = 'base64 encode decode';

export default function Base64EncodeDecode() {
    const [encoded, setEncoded] = useState('');
    const [decoded, setDecoded] = useState('');

    const onEncoded = ({target}) => {
        const decodedInput = target.value;
        Analytics.event({
            category: 'User',
            action: `used Base64EncodeDecode decoded`,
        });

        try {
            const encodedResponse = window.atob(decodedInput);
            setEncoded(encodedResponse);
            setDecoded(decodedInput);
        } catch (error) {
            setEncoded('Invalid input');
        }
    };

    const onDecoded = ({target}) => {
        const encodedInput = target.value;
        Analytics.event({
            category: 'User',
            action: `used Base64EncodeDecode encoded`,
        });

        try {
            const decodedResponse = window.btoa(encodedInput);
            setEncoded(encodedInput);
            setDecoded(decodedResponse);
        } catch (error) {
            setDecoded('Invalid Input');
        }
    };


    return (
        <Container fluid>
            <Row>
                <Col>
                    <h3>Base64 Encode/Decode</h3>
                    <p>Simple encode/decode, just paste or type in the field and it'll show in the opposite. </p>
                    <legend>Decoded</legend>
                    <p>plain string (decoded) to encode</p>
                    <textarea className="form-control-lg"
                              name="decoded"
                              value={encoded}
                              onChange={onDecoded}/>
                    <legend>Encoded</legend>
                    <p>encoded string to decode</p>
                    <textarea className="form-control-lg"
                              name="encoded"
                              value={decoded}
                              onChange={onEncoded}/>
                </Col>
            </Row>
        </Container>
    );
}

import React from 'react';
import reduceByTags from '../services/helper/reduceByTags';
import references from '../references.json';
import {Helmet} from "react-helmet";
import Dashboard from "../layout/Dashboard";
import TopNav from "../layout/nav/TopNav";
import {Container} from "react-bootstrap";
import Panel from "../layout/panel/Panel";

const linksReferences = reduceByTags(references, ['categoryItem', 'reference']);
const linksSocial = reduceByTags(references, ['categoryItem', 'social']);
const linksWebdev = reduceByTags(references, ['categoryItem', 'webdev']);
const linksPhp = reduceByTags(references, ['categoryItem', 'php']);
const linksJavascript = reduceByTags(references, ['categoryItem', 'javascript']);
const linksReact = reduceByTags(references, ['categoryItem', 'react']);
const linksNpmNode = reduceByTags(references, ['categoryItem', 'npm', 'node']);
const linksRegex = reduceByTags(references, ['categoryItem', 'regex']);
const linksGuide = reduceByTags(references, ['categoryItem', 'guide']);

export default function resources() {
   return (
       <>
           <Helmet>
               <title>Resources</title>
           </Helmet>
           <TopNav title="Resources"/>
           <Container fluid>
               <Panel>
                   <Dashboard>
                       <h2>A collection of quick reference guides and resources, focused mainly on developers.</h2>
                       <p>Want to have some added to the list? For now you can email us...</p>
                       <p>Email us at <a href="mailto:dechamp+suggestions@varymade.com">dechamp+suggestions@varymade.com</a></p>

                       <p>A lot of the links below come from this awesome repo <a
                           href="https://github.com/markerikson/react-redux-links"
                           rel="noreferrer"
                           target="_blank">markerikson/react-redux-links</a>, which
                           thanks
                           to <b>Mark Sinclair</b> for the referral to it.</p>
                   </Dashboard>
                   <Dashboard>
                       <h3>References</h3>
                       <ul>
                           {linksReferences.map((link, i) => <li key={i}><a rel="noreferrer" href={link.href} target="_blank">{link.title}</a>
                           </li>)}
                       </ul>

                       <h3>Guides</h3>
                       <ul>
                           {linksGuide.map((link, i) => <li key={i}><a rel="noreferrer" href={link.href} target="_blank">{link.title}</a></li>)}
                       </ul>

                       <h3>Social</h3>
                       <ul>
                           {linksSocial.map((link, i) => <li key={i}><a rel="noreferrer" href={link.href} target="_blank">{link.title}</a></li>)}
                       </ul>

                       <h3>Web Dev Links</h3>
                       <ul>
                           {linksWebdev.map((link, i) => <li key={i}><a rel="noreferrer" href={link.href} target="_blank">{link.title}</a></li>)}
                       </ul>

                       <h3>Php</h3>
                       <ul>
                           {linksPhp.map((link, i) => <li key={i}><a rel="noreferrer" href={link.href} target="_blank">{link.title}</a></li>)}
                       </ul>

                       <h3>JavaScript</h3>
                       <ul>
                           {linksJavascript.map((link, i) => <li key={i}><a rel="noreferrer" href={link.href} target="_blank">{link.title}</a>
                           </li>)}
                       </ul>

                       <h3>React</h3>
                       <ul>
                           {linksReact.map((link, i) => <li key={i}><a rel="noreferrer" href={link.href} target="_blank">{link.title}</a></li>)}
                       </ul>

                       <h3>Node.js/NPM</h3>
                       <ul>
                           {linksNpmNode.map((link, i) => <li key={i}><a rel="noreferrer" href={link.href} target="_blank">{link.title}</a></li>)}
                       </ul>

                       <h3>Regex</h3>
                       <ul>
                           {linksRegex.map((link, i) => <li key={i}><a rel="noreferrer" href={link.href} target="_blank">{link.title}</a></li>)}
                       </ul>
                   </Dashboard>
               </Panel>
           </Container>
       </>
   );
}

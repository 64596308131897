import React from 'react';
import { ReactComponent as LogoSvg} from '../assets/imgs/full-logo.svg';

export default function Logo() {
    return (
        <div id="logo">
            <a href="/">
                <LogoSvg/>
            </a>
        </div>
    );
}

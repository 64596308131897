import Cookie from './Cookie';

const hostnameAvailable = typeof window !== 'undefined'
    && window.hasOwnProperty('location')
    && window.location.hasOwnProperty('hostname');
const domain = hostnameAvailable ? window.location.hostname : false;
const isLocal = domain && /^(localhost|127\.0\.0\.1)$/.test(domain);
const secure = !isLocal;

export default new Cookie({secure});


const hasTags = (item, tags = []) =>
    (item && Array.from(tags).every(tag => item.tags.indexOf(tag) !== -1));

const reducer = (tags) => (acc = [], item = false, index, arr = []) => {
  if (item && hasTags(item, tags)) {
    return acc.concat(arr.splice(index, 1));
  }

  return acc;
}

export default function reduceByTags(items = [], tags = []) {
  return (
      Array.from(items)
           .reduce(reducer(tags), [])
  );
}

import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {ReactComponent as CopyIcon} from '../../../assets/icons/file_copy.svg';

export default function RgbHexDisplay({rgb = '', hex = ''}) {
    if (!rgb || !hex) {
        return <div>&nbsp;</div>;
    }

    return (
        <div>
            <CopyToClipboard text={getRgba(rgb)}>
                <div>
                    {getRgba(rgb)}
                    &nbsp;&nbsp;<CopyIcon style={{fill: 'currentColor'}}/>
                </div>
            </CopyToClipboard>
            <CopyToClipboard text={getHex(hex)}>
                <div>
                    {getHex(hex)}
                    &nbsp;&nbsp;<CopyIcon style={{fill: 'currentColor'}}/>
                </div>
            </CopyToClipboard>
        </div>
    );
}

function getRgba(rgb) {
  return rgb ? `rgba(${rgb})` : '';
}

function getHex(hex) {
  return hex ? `#${hex}` : '';
}

import {Col, Container, Row} from "react-bootstrap";
import React, {ChangeEvent, Children} from "react";

export interface TopNavProps {
    title: string;
    searchCallback?: (event: ChangeEvent<HTMLInputElement>) => void;
    children?: (JSX.Element | string)[] | (JSX.Element | string);
}

export default function TopNav({children, title, searchCallback}: TopNavProps) {
    return (
        <div id="topNav">
            <Container className="h-100" style={{width: "100%"}} fluid>
                <Row className="w-100 d-flex">
                    <Col className="justify-content-end align-items-center d-flex flex-grow-2">
                        <div className="title">
                            <h1>{title}</h1>
                        </div>
                    </Col>
                </Row>
                <Row className="pageNav d-flex justify-content-start align-items-center flex-grow-0">
                    {
                        searchCallback &&
                        <Col className="justify-content-start d-flex h-100 flex-grow-0">
                          <input onChange={searchCallback} placeholder="Search tools" className="search" type="text"
                                 name="search"/>
                        </Col>
                    }
                    {
                        children &&
                        <Col className="pageNavItems justify-content-start align-items-start d-flex flex-grow-0">
                            {Children.map(children, (child) => <span className="pageNavItem d-flex align-items-end flex-grow-1">{child}</span>)}
                        </Col>
                    }
                </Row>
            </Container>
        </div>
    );
}

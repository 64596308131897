import config from '../../config/config.json';
import _ from 'lodash';

const getLocal = () => {
  try {
    return require('../../config/local.json');
  }
  catch (error) {
    return {};
  }
};

export default _.merge(config, getLocal());

import React, {useEffect, useState} from 'react';
import {ReactComponent as PauseIcon} from '../assets/icons/pause.svg';
import {ReactComponent as PlayIcon} from '../assets/icons/play.svg';
import cookie from '../services/cookie';
import Slider from 'rc-slider';
import {Col, Row} from "react-bootstrap";
import 'rc-slider/assets/index.css';
import firstSound from "../assets/sounds/AmbientNoise1.ogg";
import Panel, {PanelVariant} from "./panel/Panel";
import Analytics from "../services/analytics/Analytics";

const play = <span>
      <PlayIcon/>
    </span>;

const pause = <span>
      <PauseIcon/>
    </span>;

const audio = new Audio(firstSound);

export default function AudioPlayer() {
    const [isPlaying, setIsPlaying] = useState(false);

    const [volume, setVolume] = useState(50);

    useEffect(() => {
       isPlaying ? audio.play() : audio.pause();
    }, [isPlaying])

    useEffect(() => {
        audio.removeEventListener('ended', () => setIsPlaying(true));

        const savedVolume = cookie.get('volume');

        cookie.set('playing', isPlaying);
        cookie.set('volume', savedVolume);

        return () => audio.removeEventListener('ended', () => setIsPlaying(false));
    }, [isPlaying]);

    useEffect(() => {
        audio.volume = volume/100;
        audio.loop = true;
    }, [volume]);

    const toggleIsPlaying = () => {
        Analytics.event({
            category: 'User',
            action: `background noise ${isPlaying ? "play" : "pause"}`,
        });

        setIsPlaying(!isPlaying);
    }

    return (
        <>
            <h2>Background Noise</h2>
            <Panel variant={PanelVariant.sidebar}>
                <div className="audioPlayer">
                    <Row className="row  backgroundNoiseControls d-flex">
                        <Col className="col-sm-2 justify-content-center align-items-center d-flex">
                            <button onClick={toggleIsPlaying}>
                                {isPlaying ? pause : play}
                            </button>
                        </Col>
                        <Col className="col-sm-10 justify-content-center align-items-center d-flex">
                            <Slider
                                defaultValue={0}
                                min={0}
                                max={100}
                                value={volume}
                                onChange={setVolume}
                            />
                        </Col>
                    </Row>
                </div>
            </Panel>
        </>
    );
};

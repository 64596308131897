import React, {useEffect, useState} from "react";
import cookie from "browser-cookies";
import {showModalHandler} from "../pages/Tools/CardViewItem";
import {MODAL_CONTENT} from "../state/stateManager/mainReducer";
import {useMainStateContext} from "../state/stateManager/mainStateManager";

export const TOOLS_LAST_USE_HISTORY = 'TOOLS_LAST_USE_HISTORY';

export default function ToolsHistory({count = 6}) {
    const [, dispatch] = useMainStateContext();
    const [history, setHistory] = useState<string[]>([]);
    const existingHistory = cookie.get(TOOLS_LAST_USE_HISTORY);

    useEffect(() => {
        const existingHistoryJson: string[] = JSON.parse(existingHistory || "[]");

        if (!existingHistoryJson) return;

        const uniqueExistingHistory = Array.from(new Set(existingHistoryJson));

        setHistory(uniqueExistingHistory.slice(0, 6));
    }, [existingHistory]);

    if (!history || history?.length < 1) return null;

    return (
        <>
            <h2>Tool History</h2>
            <div className="toolsHistory">
                {
                    history.map((historyItem, index) => {
                        return (
                            <button key={index} type="button" onClick={
                                showModalHandler(
                                    historyItem,
                                    ({body: component, title: name}) => {
                                        dispatch({
                                            type: MODAL_CONTENT,
                                            [MODAL_CONTENT]: {body: component, title: name}
                                        });
                                    })
                            } className="sidebarRecent trimary">{historyItem}</button>
                        );
                    })
                }
            </div>
        </>
    );
}

import React from "react";

interface ClockDisplayProps {
    children: number | string;
    className?: string;
}

export default function ClockDisplay({children, className}: ClockDisplayProps) {
    return (
        <div className={`${className} clockDisplay`}>
            {new Date(Number(children) * 1000).toISOString().substr(11, 8)}
        </div>
    );
}

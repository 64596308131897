import React from 'react';

export default function Thanks() {
    return (
        <div>
            <hr/>
            <p>Thanks to the package <a
                rel="noreferrer"
                href="https://www.npmjs.com/package/rgb-hex"
                target="_blank">rgb-hex</a>
                and
                <a
                    rel="noreferrer"
                    href="https://www.npmjs.com/package/hex-rgb"
                    target="_blank">hex-rgb</a>!</p>
        </div>
    );
}

import React from 'react';
import {Modal} from "react-bootstrap";
import {useMainStateContext} from "../state/stateManager/mainStateManager";
import {ACTION_CLOSE_MODAL} from "../state/stateManager/mainReducer";

export default function DefaultModal() {
    const [state, dispatch] = useMainStateContext();
    const {MODAL_CONTENT: content} = state;

    return (
        <div className="defaultModal">
            <div className="baseStyle">
                <Modal
                    size="xl"
                    onHide={() => dispatch({type: ACTION_CLOSE_MODAL})}
                    show={Boolean(content?.body)}
                    animation={true}>
                    <Modal.Header closeLabel="" closeButton>
                        {
                            content?.title &&
                            <Modal.Title>{content.title}</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body className="panel">
                        {content ? content.body : null}
                    </Modal.Body>
                    {
                        content?.footer &&
                        <Modal.Footer>
                            {content.footer}
                        </Modal.Footer>
                    }
                </Modal>
            </div>
        </div>
    );
}

import React from 'react';
import {ReactComponent as ToolIcon} from '../../assets/icons/line_style.svg';
import {ReactComponent as ResourcesIcon} from '../../assets/icons/list.svg';
import {NavLink} from 'react-router-dom';

const menuItems = [
    {
        icon: <ToolIcon/>,
        to: '/',
        exact: true,
        value: 'Tools',
    },
    {
        icon: <ResourcesIcon/>,
        to: '/resources',
        value: 'Resources',
    },
    // {
    //     icon: <ResourcesIcon/>,
    //     to: '/pageElement',
    //     value: 'PageElement',
    // },
];

export default function SidebarNav() {
    return (
        <>
            <h2>Nav</h2>
            <nav>
                {menuItems.map(
                    ({icon, to, value, exact = false}, index) => (
                        <NavLink key={index} to={to} activeClassName="active" exact={exact}>
                            {icon} {value}
                        </NavLink>
                    ))}
            </nav>
        </>
    );
}


import React, {ReactNode, useEffect} from "react";
import {useMainStateContext} from "../../state/stateManager/mainStateManager";
import {MODAL_CONTENT} from "../../state/stateManager/mainReducer";
import {TOOLS_LAST_USE_HISTORY} from "../../layout/ToolsHistory";
import cookie from 'browser-cookies';

let count = 0;
let componentList = {};

export const getComponents = () => componentList;

export const getComponent = (component) => {
    if (componentList.hasOwnProperty(component)) {
        const foundComponent = componentList[component];

        if (typeof foundComponent === 'function') {
            throw new Error("We do not support importing tools as function right now. Did you put 'Component' instead of '<Component ...>'?")
        }

        return foundComponent;
    }

    return false;
}

export const setComponents = (list) => {
    componentList = list;
}

export const removeComponent = (name) => delete componentList[name];

export const showModalHandler = (name, callback) => {
    return function() {
        const component = componentList[name];

        if (!component) return null;

        const existingHistory = cookie.get(TOOLS_LAST_USE_HISTORY);

        const existingHistoryJson = JSON.parse(existingHistory || '[]');

        cookie.set(TOOLS_LAST_USE_HISTORY, JSON.stringify([name, ...existingHistoryJson]));

        callback({body: component, title: name});
    }
};

export default function CardViewItem({i: name}: { i: string, componentList: ReactNode[] }) {
    const [, dispatch] = useMainStateContext();

    useEffect(() => {
        return () => {
            count = 0;
        };
    });

    const buttonColors = ['primary', 'secondary', 'trimary'];

    const chosenColor = count % buttonColors.length;

    count++;

    return (
        <div key={name} onClick={showModalHandler(name, ({body: component, title: name}) => {
            dispatch({
                type: MODAL_CONTENT,
                [MODAL_CONTENT]: {body: component, title: name}
            });
        })} className={`card-view button ${buttonColors[chosenColor]}`}
             style={{overflow: 'hidden'}}>
            {name}
        </div>
    );
};

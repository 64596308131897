import React, {createContext, useReducer, useContext} from 'react';
import mainReducer, {MainState} from "./mainReducer";

export const MainStateContext = createContext<any>(null);

export default function MainStateManager({initialState, children}: { initialState: any, children: any }) {
    return (
        <MainStateContext.Provider value={useReducer(mainReducer, initialState)}>
            {children}
        </MainStateContext.Provider>
    );
};

export const useMainStateContext = (): [MainState, (any) => any] => useContext(MainStateContext);

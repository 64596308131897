import React, {PureComponent} from 'react';
import Analytics from '../../services/analytics/Analytics';

const Buffer = require('buffer/').Buffer;

export default class Hex extends PureComponent {
  static getName() {
    return 'hexi ascii converter';
  }

  constructor(props) {
    super(props);

    this.state = {
      hex: '',
    };

    this.setImage = this.setInput.bind(this);
  }

  setInput({target}, byteOffset) {
    const raw = target.value;
    const offsets = ['hex', 'utf-8', 'base64', 'utf16le', 'binary'];
    const toBeUpdated = offsets.filter(offset => offset !== byteOffset);
    const updatedItems = {};

    try {
      const buff = Buffer.from(raw, byteOffset);

      toBeUpdated.forEach(item => {
        updatedItems[`${item}`] = buff.toString(item);
      });
    }
    catch (error) {
    }

    Analytics.event({
      category: 'User',
      action: `used ${this.constructor.getName()} raw`,
    });

    this.setState(state => ({
      ...state,
      [byteOffset]: raw,
      ...updatedItems,
    }));
  }

  render() {
    return (
        <div className="container-fluid">
          <div className="toolSection">
            <div className="row">
              <div className="col-sm-12">
                <h3>Hex Converter</h3>
                <p>
                  Simple text/hex converter, just paste or type in the field
                  and it'll show in the opposite.
                </p>
              </div>
              <div className="col-sm-12">
                <label htmlFor="ascii">Text: </label>
                <textarea name="ascii"
                          className="form-control-lg"
                          value={this.state['utf-8']}
                          onChange={(event) => this.setInput(event, 'ascii')}/>
              </div>
              <div className="col-sm-12">
                <label htmlFor="hex">Hex: </label>
                <textarea name="hex"
                          className="form-control-lg"
                          value={this.state.hex}
                          onChange={(event) => this.setInput(event, 'hex')}/>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <hr/>
                <p>Thanks to the package <a
                    rel="noreferrer"
                    href="https://www.npmjs.com/package/buffer"
                    target="_blank">Buffer</a>!</p>
              </div>
            </div>
          </div>
        </div>
    );
  }
};

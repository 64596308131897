import React from 'react';
import {Helmet} from "react-helmet";
import Panel from "../layout/panel/Panel";
import {Container} from 'react-bootstrap';
import Code from "../layout/Code";

export default function PageElements() {
    return (
        <>
            <Helmet>
                <title>Page Elements</title>
            </Helmet>
            <Container fluid>
                <Panel>
                    <h2>Panel Title</h2>
                    <nav role="navigation">
                        <ul className="d-flex">
                            <li className="flex-grow-1">
                                <a href="#text">Text</a>
                                <ul>
                                    <li><a href="#textHeadings">Headings</a></li>
                                    <li><a href="#textParagraphs">Paragraphs</a></li>
                                    <li><a href="#textBlockquotes">Blockquotes</a></li>
                                    <li><a href="#textLists">Lists</a></li>
                                    <li><a href="#textHr">Horizontal rules</a></li>
                                    <li><a href="#textTables">Tabular data</a></li>
                                    <li><a href="#textCode">Code</a></li>
                                    <li><a href="#textInline">Inline elements</a></li>
                                </ul>
                            </li>
                            <li className="flex-grow-1">
                                <a href="#embedded">Embedded content</a>
                                <ul>
                                    <li><a href="#embeddedImages">Images</a></li>
                                    <li><a href="#embeddedAudio">Audio</a></li>
                                    <li><a href="#embeddedVideo">Video</a></li>
                                    <li><a href="#embeddedCanvas">Canvas</a></li>
                                    <li><a href="#embeddedMeter">Meter</a></li>
                                    <li><a href="#embeddedProgress">Progress</a></li>
                                    <li><a href="#embeddedSvg">Inline SVG</a></li>
                                    <li><a href="#embeddedIframe">IFrames</a></li>
                                </ul>
                            </li>
                            <li className="flex-grow-1">
                                <a href="#forms">Form elements</a>
                                <ul>
                                    <li><a href="#formsInput">Input fields</a></li>
                                    <li><a href="#formsSelect">Select menus</a></li>
                                    <li><a href="#formsCheckbox">Checkboxes</a></li>
                                    <li><a href="#formsRadio">Radio buttons</a></li>
                                    <li><a href="#formsTextareas">Textareas</a></li>
                                    <li><a href="#formsHtml5">HTML5 inputs</a></li>
                                    <li><a href="#formsAction">Action buttons</a></li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                    <section id="text">
                        <article id="textHeadings">
                            <h3>Headers</h3>
                            <h1>Header 1</h1>
                            <h2>Header 2</h2>
                            <h3>Header 3</h3>
                            <h4>Header 4</h4>
                            <h5>Header 5</h5>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="textParagraphs">
                            <header><h2>Paragraph</h2></header>
                            <Code>
                                <p>PageElement ipsum dolor sit amet, consectetur adipiscing elit. Donec sit amet dictum dui,
                                    ac
                                    facilisis
                                    turpis.
                                    Maecenas sit amet consequat eros. Quisque condimentum dui quis magna lacinia
                                    dapibus.
                                    Aliquam
                                    aliquet
                                    molestie mi quis facilisis. Quisque sodales laoreet nisi, nec volutpat tellus semper
                                    efficitur.
                                    Ut
                                    tempus
                                    blandit porta. Duis scelerisque elit vehicula sapien pulvinar, in sagittis ex
                                    ornare.
                                    Sed
                                    mattis
                                    metus a
                                    lectus lacinia, non accumsan urna semper. Cras bibendum lobortis dolor, non
                                    condimentum
                                    libero.</p>

                                <p>Proin placerat, velit quis vulputate viverra, turpis ipsum luctus ex, et aliquam ex
                                    lectus eu
                                    dui. In
                                    cursus
                                    accumsan vehicula. Sed vel urna dolor. Aenean bibendum massa quis tincidunt
                                    elementum.
                                    Mauris
                                    quis
                                    hendrerit
                                    tellus, eget lobortis turpis. Vivamus at mi ac turpis eleifend posuere ac ut est.
                                    Suspendisse
                                    posuere
                                    consectetur dui, nec lobortis neque maximus quis. Nunc ultrices laoreet est vel
                                    efficitur.</p>
                            </Code>
                        </article>
                        <article id="textBlockquotes">
                            <header><h3>Blockquotes</h3></header>
                            <div>
                                <blockquote>
                                    <p>A block quotation (also known as a long quotation or extract) is a quotation
                                        in a
                                        written document, that is set off from the main text as a paragraph, or
                                        block of
                                        text.</p>
                                    <p>It is typically distinguished visually using indentation and a different
                                        typeface
                                        or
                                        smaller size quotation. It may or may not include a citation, usually placed
                                        at
                                        the
                                        bottom.</p>
                                    <cite><a href="#!">Said no one, ever.</a></cite>
                                </blockquote>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="textLists">
                            <header><h3>Lists</h3></header>
                            <div>
                                <h4>Definition list</h4>
                                <dl>
                                    <dt>Definition List Title</dt>
                                    <dd>This is a definition list division.</dd>
                                </dl>
                                <h4>Ordered List</h4>
                                <ol>
                                    <li>List Item 1</li>
                                    <li>List Item 2</li>
                                    <li>List Item 3</li>
                                </ol>
                                <h4>Unordered List</h4>
                                <ul>
                                    <li>List Item 1</li>
                                    <li>List Item 2</li>
                                    <li>List Item 3</li>
                                </ul>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="textHr">
                            <header><h3>Horizontal rules</h3></header>
                            <div>
                                <hr/>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="textTables">
                            <header><h3>Tabular data</h3></header>
                            <table className="table">
                                <caption>Table Caption</caption>
                                <thead>
                                <tr>
                                    <th>Table Heading 1</th>
                                    <th>Table Heading 2</th>
                                    <th>Table Heading 3</th>
                                    <th>Table Heading 4</th>
                                    <th>Table Heading 5</th>
                                </tr>
                                </thead>
                                <tfoot>
                                <tr>
                                    <th>Table Footer 1</th>
                                    <th>Table Footer 2</th>
                                    <th>Table Footer 3</th>
                                    <th>Table Footer 4</th>
                                    <th>Table Footer 5</th>
                                </tr>
                                </tfoot>
                                <tbody>
                                <tr>
                                    <td>Table Cell 1</td>
                                    <td>Table Cell 2</td>
                                    <td>Table Cell 3</td>
                                    <td>Table Cell 4</td>
                                    <td>Table Cell 5</td>
                                </tr>
                                <tr>
                                    <td>Table Cell 1</td>
                                    <td>Table Cell 2</td>
                                    <td>Table Cell 3</td>
                                    <td>Table Cell 4</td>
                                    <td>Table Cell 5</td>
                                </tr>
                                <tr>
                                    <td>Table Cell 1</td>
                                    <td>Table Cell 2</td>
                                    <td>Table Cell 3</td>
                                    <td>Table Cell 4</td>
                                    <td>Table Cell 5</td>
                                </tr>
                                <tr>
                                    <td>Table Cell 1</td>
                                    <td>Table Cell 2</td>
                                    <td>Table Cell 3</td>
                                    <td>Table Cell 4</td>
                                    <td>Table Cell 5</td>
                                </tr>
                                </tbody>
                            </table>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="textCode">
                            <header><h3>Code</h3></header>
                            <div>
                                <p><strong>Keyboard input:</strong> <kbd>Cmd</kbd></p>
                                <p><strong>Inline code:</strong> <code>&lt;div&gt;code&lt;/div&gt;</code></p>
                                <p><strong>Sample output:</strong> <samp>This is sample output from a computer
                                    program.</samp></p>
                                <h2>Pre-formatted text</h2>
                                <pre>P R E F O R M A T T E D T E X T
! " # $ % &amp; ' ( ) * + , - . /
0 1 2 3 4 5 6 7 8 9 : ; &lt; = &gt; ?
@ A B C D E F G H I J K L M N O
P Q R S T U V W X Y Z [ \ ] ^ _
` a b c d e f g h i j k l m n o
p q r s t u v w x y z  ~ </pre>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="textInline">
                            <header><h3>Inline elements</h3></header>
                            <div>
                                <p><a href="#!">This is a text link</a>.</p>
                                <p><strong>Strong is used to indicate strong importance.</strong></p>
                                <p><em>This text has added emphasis.</em></p>
                                <p>The <b>b element</b> is stylistically different text from normal text, without
                                    any
                                    special importance.</p>
                                <p>The <i>i element</i> is text that is offset from the normal text.</p>
                                <p>The <u>u element</u> is text with an unarticulated, though explicitly rendered,
                                    non-textual annotation.</p>
                                <p>
                                    <del>This text is deleted</del>
                                    and <ins>This text is inserted</ins>.
                                </p>
                                <p><s>This text has a strikethrough</s>.</p>
                                <p>Superscript<sup>®</sup>.</p>
                                <p>Subscript for things like H<sub>2</sub>O.</p>
                                <p>
                                    <small>This small text is small for for fine print, etc.</small>
                                </p>
                                <p>Abbreviation: <abbr title="HyperText Markup Language">HTML</abbr></p>
                                <p><q cite="https://developer.mozilla.org/en-US/docs/HTML/Element/q">This text is a
                                    short
                                    inline quotation.</q></p>
                                <p><cite>This is a citation.</cite></p>
                                <p>The <dfn>dfn element</dfn> indicates a definition.</p>
                                <p>The <mark>mark element</mark> indicates a highlight.</p>
                                <p>The <var>variable element</var>, such as <var>x</var> = <var>y</var>.</p>
                                <p>The time element: <time dateTime="2013-04-06T12:32+00:00">2 weeks ago</time></p>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                    </section>
                    <section id="embedded">
                        <header><h3>Embedded content</h3></header>
                        <article id="embeddedImages">
                            <header><h2>Images</h2></header>
                            <div>
                                <h3>No <code>&lt;figure&gt;</code> element</h3>
                                <p><img src="http://placekitten.com/480/480" alt="alt text"/></p>
                                <h3>Wrapped in a <code>&lt;figure&gt;</code> element,
                                    no <code>&lt;figcaption&gt;</code>
                                </h3>
                                <figure><img src="http://placekitten.com/420/420" alt="alt text"/></figure>
                                <h3>Wrapped in a <code>&lt;figure&gt;</code> element, with
                                    a <code>&lt;figcaption&gt;</code>
                                </h3>
                                <figure>
                                    <img src="http://placekitten.com/420/420" alt="alt text"/>
                                    <figcaption>Here is a caption for this image.</figcaption>
                                </figure>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="embeddedAudio">
                            <header><h2>Audio</h2></header>
                            <div>
                                <audio>audio</audio>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="embeddedVideo">
                            <header><h2>Video</h2></header>
                            <div>
                                <video>video</video>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="embeddedCanvas">
                            <header><h2>Canvas</h2></header>
                            <div>
                                <canvas>canvas</canvas>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="embeddedMeter">
                            <header><h2>Meter</h2></header>
                            <div>
                                <meter value="2" min="0" max="10">2 out of 10</meter>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="embeddedProgress">
                            <header><h2>Progress</h2></header>
                            <div>
                                <progress>progress</progress>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="embeddedSvg">
                            <header><h2>Inline SVG</h2></header>
                            <div>
                                <svg width="100px" height="100px">
                                    <circle cx="100" cy="100" r="100" fill="#1fa3ec"/>
                                </svg>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                        <article id="embeddedIframe">
                            <header><h2>IFrame</h2></header>
                            <div>
                                <iframe title="iframe" src="index.html" height="300"></iframe>
                            </div>
                            <footer><p><a href="#top">[Top]</a></p></footer>
                        </article>
                    </section>
                    <section id="forms">
                        <header><h3>Form elements</h3></header>
                        <form>
                            <fieldset id="formsInput">
                                <legend>Input fields</legend>
                                <p>
                                    <label htmlFor="inputtext">Text Input</label>
                                    <input id="inputtext" type="text" placeholder="Text Input"/>
                                </p>
                                <p>
                                    <label htmlFor="inputpassword">Password</label>
                                    <input id="inputpassword" type="password" placeholder="Type your Password"/>
                                </p>
                                <p>
                                    <label htmlFor="inputwebaddress">Web Address</label>
                                    <input id="inputwebaddress" type="url" placeholder="http://yoursite.com"/>
                                </p>
                                <p>
                                    <label htmlFor="inputemailaddress">Email Address</label>
                                    <input id="inputemailaddress" type="email" placeholder="name@email.com"/>
                                </p>
                                <p>
                                    <label htmlFor="inputphone">Phone Number</label>
                                    <input id="inputphone" type="tel" placeholder="(999) 999-9999"/>
                                </p>
                                <p>
                                    <label htmlFor="inputsearch">Search</label>
                                    <input id="inputsearch" type="search" placeholder="Enter Search Term"/>
                                </p>
                                <p>
                                    <label htmlFor="inputtext2">Number Input</label>
                                    <input id="inputtext2" type="number" placeholder="Enter a Number"/>
                                </p>
                                <p>
                                    <label htmlFor="inputtext3" className="error">Error</label>
                                    <input id="inputtext3" className="is-error" type="text"
                                           placeholder="Text Input"/>
                                </p>
                                <p>
                                    <label htmlFor="inputtext4" className="valid">Valid</label>
                                    <input id="inputtext4" className="is-valid" type="text"
                                           placeholder="Text Input"/>
                                </p>
                            </fieldset>
                            <p><a href="#top">[Top]</a></p>
                            <fieldset id="formsSelect">
                                <legend>Select menus</legend>
                                <p>
                                    <label htmlFor="select">Select</label>
                                    <select id="select">
                                        <optgroup label="Option Group">
                                            <option>Option One</option>
                                            <option>Option Two</option>
                                            <option>Option Three</option>
                                        </optgroup>
                                    </select>
                                </p>
                            </fieldset>
                            <p><a href="#top">[Top]</a></p>
                            <fieldset id="formsCheckbox">
                                <legend>Checkboxes</legend>
                                <ul className="list list--bare">
                                    <li><label htmlFor="checkbox1"><input id="checkbox1" name="checkbox"
                                                                          type="checkbox"
                                                                          checked={true}/> Choice A</label></li>
                                    <li><label htmlFor="checkbox2"><input id="checkbox2" name="checkbox"
                                                                          type="checkbox"/> Choice B</label></li>
                                    <li><label htmlFor="checkbox3"><input id="checkbox3" name="checkbox"
                                                                          type="checkbox"/> Choice C</label></li>
                                </ul>
                            </fieldset>
                            <p><a href="#top">[Top]</a></p>
                            <fieldset id="formsRadio">
                                <legend>Radio buttons</legend>
                                <ul className="list list--bare">
                                    <li><label htmlFor="radio1"><input id="radio1" name="radio" type="radio"
                                                                       className="radio" checked={true}/> Option
                                        1</label>
                                    </li>
                                    <li><label htmlFor="radio2"><input id="radio2" name="radio" type="radio"
                                                                       className="radio"/> Option 2</label></li>
                                    <li><label htmlFor="radio3"><input id="radio3" name="radio" type="radio"
                                                                       className="radio"/> Option 3</label></li>
                                </ul>
                            </fieldset>
                            <p><a href="#top">[Top]</a></p>
                            <fieldset id="formsTextareas">
                                <legend>Textareas</legend>
                                <p>
                                    <label htmlFor="textarea">Textarea</label>
                                    <textarea id="textarea" rows={8} cols={48}
                                              placeholder="Enter your message here"></textarea>
                                </p>
                            </fieldset>
                            <p><a href="#top">[Top]</a></p>
                            <fieldset id="formsHtml5">
                                <legend>HTML5 inputs</legend>
                                <p>
                                    <label htmlFor="ic">Color input</label>
                                    <input type="color" id="ic" value="#000000"/>
                                </p>
                                <p>
                                    <label htmlFor="in">Number input</label>
                                    <input type="number" id="in" min="0" max="10" value="5"/>
                                </p>
                                <p>
                                    <label htmlFor="ir">Range input</label>
                                    <input type="range" id="ir" value="10"/>
                                </p>
                                <p>
                                    <label htmlFor="idd">Date input</label>
                                    <input type="date" id="idd" value="1970-01-01"/>
                                </p>
                                <p>
                                    <label htmlFor="idm">Month input</label>
                                    <input type="month" id="idm" value="1970-01"/>
                                </p>
                                <p>
                                    <label htmlFor="idw">Week input</label>
                                    <input type="week" id="idw" value="1970-W01"/>
                                </p>
                                <p>
                                    <label htmlFor="idt">Datetime input</label>
                                    <input type="datetime" id="idt" value="1970-01-01T00:00:00Z"/>
                                </p>
                                <p>
                                    <label htmlFor="idtl">Datetime-local input</label>
                                    <input type="datetime-local" id="idtl" value="1970-01-01T00:00"/>
                                </p>
                            </fieldset>
                            <p><a href="#top">[Top]</a></p>
                            <fieldset id="formsAction">
                                <legend>Action buttons</legend>
                                <p>
                                    <input type="submit" value="<input type=submit>"/>
                                    <input type="button" value="<input type=button>"/>
                                    <input type="reset" value="<input type=reset>"/>
                                    <input type="submit" value="<input disabled>" disabled={true}/>
                                </p>
                                <p>
                                    <button type="submit">&lt;button type=submit&gt;</button>
                                    <button type="button">&lt;button type=button&gt;</button>
                                    <button type="reset">&lt;button type=reset&gt;</button>
                                    <button type="button" disabled>&lt;button disabled&gt;</button>
                                </p>
                            </fieldset>
                            <p><a href="#top">[Top]</a></p>
                        </form>
                    </section>
                    <footer role="contentinfo">
                        <p>Made by <a href="http://twitter.com/cbracco">@cbracco</a>. Code on <a
                            href="http://github.com/cbracco/html5-test-page">GitHub</a>.</p>
                    </footer>
                </Panel>
            </Container>
        </>
    );
}

import React from 'react';
import Logo from '../Logo';
import DefaultModal from "../DefaultModal";
import ToolsHistory from '../ToolsHistory';
import AudioPlayer from "../AudioPlayer";
import SidebarNav from "../nav/SidebarNav";

export default function Sidebar({children}) {
    return (
        <div id="layoutSidebarMain">
            <div id="sidebar">
                <Logo/>
                <div id="intro">
                    <p>A helpful dashboard with tools, resources and more, for
                        developers.</p>
                </div>
                <SidebarNav/>
                <ToolsHistory/>
                <AudioPlayer/>
            </div>
            <div className="main">
                <div id="mainBackgroundSplash"></div>
                {children || 'no content'}
                <DefaultModal/>
            </div>
        </div>
    );
}

import React, {ReactNode, useEffect, useState} from "react";
import cookie from "browser-cookies";
import {TOOLS_LAYOUT} from "./ToolsResponsiveView";
import {Layout, Layouts, Responsive, WidthProvider} from "react-grid-layout";
import config from '../../services/config';

const toolLayouts = config.layouts.tools;

export interface ResponsiveWidgetsProps {
    toolsConfig: any;
    element: (toolConfig) => JSX.Element;
}

export default function ResponsiveWidgets({
                                              element,
                                              toolsConfig,
                                          }: ResponsiveWidgetsProps) {
    const [layoutConfig, setLayoutConfig] = useState(toolsConfig);

    useEffect(() => {
        const layoutCookie = cookie.get(TOOLS_LAYOUT) as string;
        const layoutCookieJson = JSON.parse(layoutCookie);

        if (layoutCookieJson) {
            setLayoutConfig(layoutCookieJson);
        }
    }, []);

    useEffect(() => {
        if (layoutConfig.lg.length < 1) {
            setLayoutConfig(toolsConfig);
        }
    }, [layoutConfig, toolsConfig]);

    const ResponsiveGridLayout = WidthProvider(Responsive);

    return <ResponsiveGridLayout className="layout"
                                 layouts={toolLayouts}
                                 breakpoints={{lg: 1200, md: 1024, sm: 768, xs: 480, xxs: 0}}
                                 cols={{lg: 6, md: 4, sm: 2, xs: 1, xxs: 1}}
                                 margin={[15, 15]}
                                 rowHeight={10}
                                 onLayoutChange={(currentLayout: Layout[], allLayouts: Layouts) => {
                                     console.log('allLayouts', allLayouts);
                                     cookie.set(TOOLS_LAYOUT, JSON.stringify(allLayouts));
                                 }}
                                 draggableHandle=".dragHandle"
                                 style={{margin: "-30px"}}
    >
        {
            // @ts-ignore
            toolsConfig.lg.map(element) as ReactNode
        }
    </ResponsiveGridLayout>;
}

import React, {ReactNode} from 'react';
import {Col, Row} from "react-bootstrap";

export enum PanelVariant {
    white = "white",
    transparent = 'transparent',
    sidebar = 'sidebar',
    tool = 'tool'
}

export interface PanelProps {
    title?: string;
    children: ReactNode;

    [key: string]: any;
}

export default function Panel({children, title, variant}: PanelProps) {
    return (
        <div className={`panel ${variant || ''}`}>
            {
                title &&
                <Row>
                  <Col>
                    <h2>{title}</h2>
                  </Col>
                </Row>
            }
            <Row>
                <Col style={{boxSizing: 'border-box'}}>
                    {children}
                </Col>
            </Row>
        </div>
    );
};

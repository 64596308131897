import React, {PureComponent} from 'react';
import Analytics from '../../services/analytics/Analytics';

export default class ListModifier extends PureComponent {
  static getName() {
    return 'list modifier';
  }

  constructor(props) {
    super(props);

    this.state = {
      input: '',
      output: '',
      prepend: '',
      append: '',
    };

    this.setInput = this.setInput.bind(this);
    this.setPrepend = this.setPrepend.bind(this);
    this.setAppend = this.setAppend.bind(this);
  }

  setInput({target}) {
    const input = target.value;
    Analytics.event({
      category: 'User',
      action: `used ${this.constructor.getName()}`,
    });
    this.setState(state => ({...state, input}));
    this.transformInput(input);
  }

  transformInput(input) {
    const items = input.split('\n');
    const output = items.map(item => this.transformInputItem(item)).join('\n');

    this.setState(state => ({...state, output}));
  }

  transformInputItem(item) {
    const {prepend, append} = this.state;

    return `${prepend}${item}${append}`;
  }

  setPrepend({target}) {
    const prepend = target.value;

    this.setState(state => ({...state, prepend}), () => {
      this.transformInput(this.state.input);
    });
  }

  setAppend({target}) {
    const append = target.value;
    this.setState(state => ({...state, append}), () => {
      this.transformInput(this.state.input);
    });
  }

  render() {
    return (
        <div className="container-fluid">
          <div className="toolSection">
            <div className="row">
              <div className="col-sm-12">
                <h3>List Modifier</h3>
                <p>
                  Prepend and append text to each line. You can paste in a
                  multiline list and for each line, what ever
                  you put in prepend/append will be added.
                </p>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="prepend">Prepend Value: </label>
                  <input className="form-inline form-control-lg" name="prepend"
                         onChange={this.setPrepend}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="append">Append Value: </label>
                  <input className="form-inline form-control-lg" name="append"
                         onChange={this.setAppend}/>
                </div>
              </div>
              <div className="col-sm-12">
                <label htmlFor="input">Input: </label>
                <textarea className="form-control-lg" name="input" onChange={this.setInput}></textarea>
              </div>
              <div className="col-sm-12">
                <label htmlFor="output">Output: </label>
                <textarea className="form-control-lg" name="output" readOnly={true} value={this.state.output}></textarea>
              </div>
            </div>
          </div>
        </div>
    );
  }
};
